import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userId: '',
    avatar: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userId = action.payload._id
            state.avatar = action.payload.avatar
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            state.userName = action.payload.userName
            state.email = action.payload.email
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
