import React from 'react'
import PropTypes from 'prop-types'
import { CircleCheck, CircleX, Info, TriangleAlert } from 'lucide-react'

const ICONS = {
    success: {
        color: 'text-emerald-400',
        icon: <CircleCheck size={16} strokeWidth={1} />,
    },
    info: {
        color: 'text-blue-400',
        icon: <Info size={16} strokeWidth={1} />,
    },
    warning: {
        color: 'text-yellow-400',
        icon: <TriangleAlert size={16} strokeWidth={1} />,
    },
    danger: {
        color: 'text-red-400',
        icon: <CircleX size={16} strokeWidth={1} />,
    },
}

const StatusIcon = (props) => {
    const { type, custom, iconColor } = props

    const icon = ICONS[type]

    return (
        <span className={`text-2xl ${iconColor || icon.color}`}>
            {custom || icon.icon}
        </span>
    )
}

StatusIcon.defaultProps = {
    type: 'info',
}

StatusIcon.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
    custom: PropTypes.node,
}

export default StatusIcon
