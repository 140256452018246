import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { inboxList } from 'services/InboxService'

export const getTasksList = createAsyncThunk(
    'inbox/data/getTasksList',
    async (data) => {
        const response = await inboxList(data)
        return response?.data?.data
    }
)

const inboxDataSlice = createSlice({
    name: 'inbox/data',
    initialState: {
        loading: false,
        inbox: {
            totalCount: 0,
            tasks: [],
        },
        shouldRefetchInbox: false,
    },
    reducers: {
        setShouldRefetchInbox: (state, action) => {
            state.shouldRefetchInbox = action.payload
        },
    },
    extraReducers: {
        [getTasksList.pending]: (state) => {
            state.loading = true
        },
        [getTasksList.fulfilled]: (state, action) => {
            state.inbox = {
                ...action.payload,
            }
            state.loading = false
            state.shouldRefetchInbox = false
        },
    },
})

export const { setShouldRefetchInbox } = inboxDataSlice.actions

export default inboxDataSlice.reducer
