import React from 'react'
import classNames from 'classnames'
import Tooltip from '../Tooltip'
import { X } from 'lucide-react'

const CloseButton = React.forwardRef((props, ref) => {
    const {
        absolute,
        className,
        defaultStyle,
        showCloseTooltip,
        wrapperClass,
        size,
        tooltip,
        ...rest
    } = props
    const closeButtonAbsoluteClass = 'absolute z-10'

    const closeButtonClass = classNames(
        'close-btn',
        defaultStyle && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className
    )

    return (
        <span className={closeButtonClass} role="button" {...rest} ref={ref}>
            {showCloseTooltip ? (
                <Tooltip
                    wrapperClass={wrapperClass}
                    title={
                        tooltip ? (
                            tooltip
                        ) : (
                            <p className="flex items-center gap-x-2">
                                Press
                                <kbd className="shortcut">Esc</kbd>
                                to close item
                            </p>
                        )
                    }
                >
                    <X strokeWidth={1} size={size} />
                </Tooltip>
            ) : (
                <div className={wrapperClass}>
                    <X strokeWidth={1} size={size} />
                </div>
            )}
        </span>
    )
})

CloseButton.defaultProps = {
    defaultStyle: true,
    showCloseTooltip: false,
    wrapperClass: '',
    size: 16,
}

export default CloseButton
