import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    usersAndTeams: { users: [], teams: [] },
}

export const userDataSlice = createSlice({
    name: 'user/data',
    initialState,
    reducers: {
        setUsersAndTeams: (state, action) => {
            state.usersAndTeams = action.payload
        },
    },
})

export const { setUsersAndTeams } = userDataSlice.actions

export default userDataSlice.reducer
