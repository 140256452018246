import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'inbox/state',
    initialState: {
        view: 'grid',
    },
    reducers: {
        toggleView: (state, action) => {
            state.view = action.payload
        },
    },
})

export const { toggleView } = stateSlice.actions

export default stateSlice.reducer
