import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/ui'
import { Plus } from 'lucide-react'

const CreateFlowButton = ({ size = 'sm', onClick }) => {
    return (
        <Button
            size={size}
            variant="solid"
            className="text-sm py-0 !rounded"
            icon={<Plus size={20} strokeWidth={1} />}
            onClick={onClick}
        >
            <span className="text-sm font-normal">Create flow</span>
        </Button>
    )
}

CreateFlowButton.propTypes = {
    size: PropTypes.string,
    onClick: PropTypes.func,
}

CreateFlowButton.defaultProps = {}

export default CreateFlowButton
