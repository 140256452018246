import React from 'react'
import { Button } from 'components/ui'
import { Ellipsis } from 'lucide-react'

const EllipsisButton = (props) => {
    const { shape = 'round', variant = 'plain', size = 'sm' } = props

    return (
        <Button
            shape={shape}
            variant={variant}
            size={size}
            icon={<Ellipsis strokeWidth={1} />}
            {...props}
        />
    )
}

export default EllipsisButton
