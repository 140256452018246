import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import signUp from './signUpSlice'

const reducer = combineReducers({
    common,
    signUp,
})

export default reducer
