export const SIZES = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
}

export const CONTROL_SIZES = {
    [SIZES.XS]: 7,
    [SIZES.SM]: 9,
    [SIZES.MD]: 11,
    [SIZES.LG]: 14,
}

export const LAYOUT = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    INLINE: 'inline',
}

export const DIRECTIONS = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
}

export const SELECTION_MODES = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day',
}

export const PICKER_VIEWS = {
    YEAR: 'year',
    MONTH: 'month',
    DATE: 'date',
}

export const STATUS = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
}

export const STEPS_STATUS = {
    COMPLETE: 'complete',
    PENDING: 'pending',
    IN_PROGRESS: 'in-progress',
    ERROR: 'error',
}

export const PLACEMENT = {
    TOP_START: 'top-start',
    TOP_CENTER: 'top-center',
    TOP_END: 'top-end',
    BOTTOM_START: 'bottom-start',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_END: 'bottom-end',
    MIDDLE_START_TOP: 'middle-start-top',
    MIDDLE_START_BOTTOM: 'middle-start-bottom',
    MIDDLE_END_TOP: 'middle-end-top',
    MIDDLE_END_BOTTOM: 'middle-end-bottom',
}

export const DROPDOWN_ITEM_TYPE = {
    DEFAULT: 'default',
    HEADER: 'header',
    DIVIDER: 'divider',
    CUSTOM: 'custom',
}

export const DAY_DURATION = 86400000

export const APP_ICONS = [
    'icon-airplane.svg',
    'icon-cog.svg',
    'icon-globe.svg',
    'icon-mail.svg',
    'icon-store.svg',
    'icon-announcement.svg',
    'icon-collection.svg',
    'icon-hard-drive.svg',
    'icon-monitor.svg',
    'icon-survey.svg',
    'icon-asterisk.svg',
    'icon-compass.svg',
    'icon-headphones.svg',
    'icon-mood-happy.svg',
    'icon-swatch.svg',
    'icon-bolt.svg',
    'icon-credit-card.svg',
    'icon-heart.svg',
    'icon-notification.svg',
    'icon-tag.svg',
    'icon-book-closed.svg',
    'icon-currency-dollar.svg',
    'icon-help.svg',
    'icon-office.svg',
    'icon-target.svg',
    'icon-book-open.svg',
    'icon-currency-euro.svg',
    'icon-history.svg',
    'icon-package.svg',
    'icon-thumbs-up.svg',
    'icon-box.svg',
    'icon-dashboard.svg',
    'icon-home.svg',
    'icon-pie-chart.svg',
    'icon-ticket.svg',
    'icon-brick.svg',
    'icon-deliver.svg',
    'icon-hour-glass.svg',
    'icon-puzzle.svg',
    'icon-time.svg',
    'icon-bug.svg',
    'icon-desktop.svg',
    'icon-identification.svg',
    'icon-scale.svg',
    'icon-trending-up.svg',
    'icon-buoy.svg',
    'icon-discount.svg',
    'icon-inbox-check.svg',
    'icon-security.svg',
    'icon-trophy.svg',
    'icon-calculator.svg',
    'icon-door-enter.svg',
    'icon-key.svg',
    'icon-send.svg',
    'icon-tune.svg',
    'icon-calendar.svg',
    'icon-door-exit.svg',
    'icon-launch.svg',
    'icon-server.svg',
    'icon-umbrella.svg',
    'icon-certificate.svg',
    'icon-factory.svg',
    'icon-layers.svg',
    'icon-shopping-bag.svg',
    'icon-user-couple.svg',
    'icon-chart.svg',
    'icon-fast-forward.svg',
    'icon-library.svg',
    'icon-shopping-basket.svg',
    'icon-wallet.svg',
    'icon-chat.svg',
    'icon-fast-rewind.svg',
    'icon-light.svg',
    'icon-shopping-cart.svg',
    'icon-widget-add.svg',
    'icon-clouds.svg',
    'icon-film.svg',
    'icon-location-pin.svg',
    'icon-sign.svg',
    'icon-work.svg',
    'icon-code.svg',
    'icon-flag.svg',
    'icon-lock.svg',
    'icon-star.svg',
]

export const ICON_COLORS = [
    'gray',
    'red',
    'orange',
    'amber',
    'yellow',
    'emerald',
    'green',
    'lime',
    'blue',
    'sky',
    'cyan',
    'violet',
    'purple',
    'fuchsia',
    'pink',
    'rose',
]
