import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignInSuccess, onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]
let isRefreshing = false
let requestQueue = []

const BaseService = axios.create({
    timeout: 60000,
    baseURL: `${process.env.REACT_APP_BASE_URL}${appConfig.apiPrefix}`,
    withCredentials: true,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        const accessToken =
            localStorage.getItem('token') || persistData.auth.session.token

        if (!config?.headers?.Authorization && accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response, config } = error

        if (
            response &&
            unauthorizedCode.includes(response.status) &&
            !config._retry
        ) {
            config._retry = true
            if (!isRefreshing) {
                isRefreshing = true
                try {
                    const newToken = await refreshToken() // Implement this function
                    // Update the Authorization header with the new token
                    const updatedConfig = { ...config } // Create a copy of config
                    updatedConfig.headers[
                        'Authorization'
                    ] = `${TOKEN_TYPE}${newToken}`

                    // Retry the original request with the updated token
                    const response = await axios(updatedConfig)
                    return response
                } catch (refreshError) {
                    // Sign out the user if token refresh fails
                    localStorage.removeItem('token')
                    store.dispatch(onSignOutSuccess())
                    return Promise.reject(refreshError)
                } finally {
                    isRefreshing = false
                }
            } else {
                // If a refresh is already in progress, enqueue the request
                return new Promise((resolve, reject) => {
                    requestQueue.push({ config, resolve, reject })
                })
            }
        }

        return Promise.reject(error)
    }
)

const refreshToken = async () => {
    try {
        // Make a request to your backend to refresh the access token
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/refresh-tokens`,
            null,
            {
                withCredentials: true, // Ensure cookies are sent with the request
            }
        )

        // Assuming your backend returns the new access token in the response
        const { token } = response.data
        // Update the stored access token with the new one (if needed)
        localStorage.setItem('token', token)
        store.dispatch(onSignInSuccess(token))
        executeQueue(token)
        return token
    } catch (error) {
        throw error
    }
}

const executeQueue = (token) => {
    while (requestQueue.length > 0) {
        const { config, resolve, reject } = requestQueue.shift()
        // Ensure config object is available and update headers for each request
        if (config && config.headers) {
            const updatedConfig = { ...config } // Create a copy to avoid mutating the original config
            updatedConfig.headers['Authorization'] = `${TOKEN_TYPE}${token}`
            axios(updatedConfig).then(resolve).catch(reject)
        } else {
            reject(new Error('Invalid request configuration'))
        }
    }
}

export default BaseService
