import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'task/state',
    initialState: {
        dialogOpen: false,
        taskDetailsOpen: false,
        selectedTask: {},
        rejectReason: '',
    },
    reducers: {
        openDialog: (state) => {
            state.dialogOpen = true
        },
        closeDialog: (state) => {
            state.dialogOpen = false
        },
        openTaskDetailsDialog: (state, action) => {
            state.selectedTask = action.payload
            state.taskDetailsOpen = true
        },
        closeTaskDetailsDialog: (state, action) => {
            state.selectedTask = {}
            state.taskDetailsOpen = false
        },
        setRejectReason: (state, action) => {
            state.rejectReason = action.payload
        },
        setSelectedTask: (state, action) => {
            state.selectedTask = action.payload
        },
    },
})

export const {
    openDialog,
    closeDialog,
    openTaskDetailsDialog,
    closeTaskDetailsDialog,
    setRejectReason,
    setSelectedTask,
} = stateSlice.actions

export default stateSlice.reducer
