import ApiService from './ApiService'

export async function inboxList(data) {
    return ApiService.fetchData({
        url: '/tasks',
        method: 'get',
        params: data.params,
    })
}

export async function getTaskById(data) {
    return ApiService.fetchData({
        url: `/tasks/${data.params.key}`,
        method: 'get',
        params: data.params,
    })
}

export async function submitTask(taskId, processKey, data) {
    return ApiService.fetchData({
        url: `/tasks/${taskId}/submit`,
        method: 'post',
        params: { processKey },
        data,
    })
}

export async function updateTask(taskId, processKey, data) {
    return ApiService.fetchData({
        url: `/tasks/${taskId}`,
        method: 'put',
        params: { processKey },
        data,
    })
}
