import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchComments } from 'services/CommentService'

export const getComments = createAsyncThunk(
    'task/data/getComments',
    async (data) => {
        const response = await fetchComments(data)
        return response.data
    }
)

const taskDataSlice = createSlice({
    name: 'task/data',
    initialState: {
        task: {},
        comments: [],
        loading: false,
    },
    reducers: {
        setTaskData: (state, action) => {
            state.task = action.payload
        },
    },
    extraReducers: {
        [getComments.pending]: (state) => {
            state.loading = true
        },
        [getComments.fulfilled]: (state, action) => {
            state.comments = { ...action.payload.data }
            state.loading = false
        },
    },
})

export const { setTaskData } = taskDataSlice.actions

export default taskDataSlice.reducer
