import React from 'react'
import { Menu } from 'lucide-react'

const NavToggle = ({ toggled, className }) => {
    return (
        <div className={className}>
            {toggled ? (
                <Menu size={16} strokeWidth={1} />
            ) : (
                <Menu size={16} strokeWidth={1} />
            )}
        </div>
    )
}

export default NavToggle
