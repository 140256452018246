import React, { memo, useRef, useEffect } from 'react'
import { Input } from 'components/ui'
import PropTypes from 'prop-types'
import { Search } from 'lucide-react'
import { debounce } from 'lodash'

const SearchInput = ({ placeholder, className, onChange }) => {
    const debouncedOnChange = useRef(
        debounce((value) => onChange?.(value), 500)
    )

    useEffect(() => {
        return () => {
            debouncedOnChange.current.cancel() // Cleanup debounce timer on unmount
        }
    }, [])

    const handleChange = (event) => {
        debouncedOnChange.current(event.target.value)
    }

    return (
        <div className={`relative flex items-center ${className}`}>
            <Input
                placeholder={placeholder}
                prefix={<Search size={18} strokeWidth={1} />}
                className="font-normal w-[240px]"
                size="sm"
                onChange={handleChange}
            />
        </div>
    )
}

SearchInput.defaultProps = {
    placeholder: 'Type here to search',
    className: '', // Default class if none provided
}

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default memo(SearchInput)
