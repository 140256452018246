import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    data: [],
}

export const flowListSlice = createSlice({
    name: 'flow/list',
    initialState,
    reducers: {
        setFlowData: (state, action) => {
            state.data = action.payload
        },
    },
})

export const { setFlowData } = flowListSlice.actions

export default flowListSlice.reducer
