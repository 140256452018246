import ApiService from './ApiService'

export async function authSelf() {
    return ApiService.fetchData({
        url: '/auth/self',
        method: 'get',
    })
}

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/auth/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/auth/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/auth/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword({ token, ...data }) {
    return ApiService.fetchData({
        url: '/auth/reset-password',
        method: 'post',
        data,
        headers: {
            Authorization: token,
        },
    })
}
