import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import axios from 'axios'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import './locales'
import ScrollToTop from 'components/shared/ScrollToTop'
import { onSignInSuccess } from 'store/auth/sessionSlice'

function App() {
    const [initialTokenRefreshed, setInitialTokenRefreshed] = useState(false)

    useEffect(() => {
        // Set up interval to call refreshAccessToken every 15 minutes
        const intervalId = setInterval(refreshAccessToken, 15 * 60 * 1000)

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId)
    }, [])

    const isAuthPage = (pathname) => {
        // Define your authentication-related page paths here
        const authPages = ['/sign-in', '/sign-up', '/forgot-password']
        return authPages.includes(pathname)
    }

    useEffect(() => {
        if (isAuthPage(window?.location) && initialTokenRefreshed) {
            window.location.href = '/inbox'
        }
    }, [initialTokenRefreshed])

    const refreshAccessToken = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/auth/refresh-tokens`,
                null,
                {
                    withCredentials: true,
                }
            )
            const { token } = response.data
            localStorage.setItem('token', token)
            store.dispatch(onSignInSuccess(token))
            setInitialTokenRefreshed(true)
        } catch (error) {
            console.error('Error refreshing access token:', error)
            window.location.href = '/sign-in'
        }
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ScrollToTop />
                <Theme>
                    <Layout />
                </Theme>
            </PersistGate>
        </Provider>
    )
}

export default App
