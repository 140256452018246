import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { onSignUpSuccess } from 'store/base/signUpSlice'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn, signUpData } = useSelector(
        (state) => state.auth.session
    )

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { token } = resp.data
                localStorage.setItem('token', token)

                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    const { _id, email, firstName, lastName, avatar } =
                        resp.data.user

                    dispatch(
                        setUser({
                            _id,
                            avatar,
                            firstName,
                            lastName,
                            email,
                            userName: `${firstName} ${lastName}`,
                        })
                    )
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            dispatch(
                onSignUpSuccess({
                    userId: resp?.data?._id,
                    firstName: resp?.data?.firstName,
                    lastName: resp?.data?.lastName,
                    email: resp?.data?.email,
                })
            )
            navigate('/welcome-user')
            return {
                status: 'success',
                message: '',
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        localStorage.removeItem('token')
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
