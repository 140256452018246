import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import user from './user'
import category from './category'
import flowGlobal from './flow'
import flow from '../views/pages/Flow/store'
import task from '../views/pages/Task/store'
import inbox from '../views/pages/Inbox/store'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        user,
        base,
        flow,
        flowGlobal,
        task,
        category,
        inbox,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
