import { createSlice } from '@reduxjs/toolkit'

export const signUpSlice = createSlice({
    name: 'base/signUp',
    initialState: {
        signUpData: {
            firstName: '',
            lastName: '',
            email: '',
            userId: '',
        },
    },
    reducers: {
        onSignUpSuccess: (state, action) => {
            state.signUpData = { ...action.payload }
        },
        clearSignUpData: (state) => {
            state.signUpData = {
                firstName: '',
                lastName: '',
                email: '',
                userId: '',
            }
        },
    },
})

export const { onSignUpSuccess, clearSignUpData } = signUpSlice.actions

export default signUpSlice.reducer
