import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/ui'
import classNames from 'classnames'

const ActionButton = (props) => {
    const { buttonClass, icon, size = 'sm', shape } = props

    return (
        <Button
            type="button"
            size={size}
            shape={shape}
            className={classNames(
                'hover:bg-gray-50 rounded border-0',
                buttonClass
            )}
            icon={icon}
            {...props}
        />
    )
}

ActionButton.defaultProps = {
    buttonClass: '',
    shape: 'round',
}

ActionButton.propTypes = {
    icon: PropTypes.node,
    size: PropTypes.string,
    shape: PropTypes.oneOf(['round', 'circle', 'none']),
    buttonClass: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

export default ActionButton
