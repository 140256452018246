import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    flowRunDialog: {
        open: false,
        flowId: '',
        name: '',
        subject: '',
    },
}

export const flowSlice = createSlice({
    name: 'flow/global',
    initialState,
    reducers: {
        setFlowRunDialog: (state, action) => {
            state.flowRunDialog = action.payload
        },
    },
})

export const { setFlowRunDialog } = flowSlice.actions

export default flowSlice.reducer
