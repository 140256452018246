import ApiService from './ApiService'

export async function fetchComments(data) {
    return ApiService.fetchData({
        url: `/comments/${data.processId}`,
        method: 'get',
    })
}

export async function addComment(data) {
    return ApiService.fetchData({
        url: '/comments',
        method: 'post',
        data,
    })
}

export async function updateComment(commentId, data) {
    return ApiService.fetchData({
        url: `/comments/${commentId}`,
        method: 'put',
        data,
    })
}

export async function deleteComment(data) {
    return ApiService.fetchData({
        url: `/comments/${data.id}`,
        method: 'delete',
    })
}
