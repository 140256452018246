import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        // Return the promise from BaseService
        return BaseService(param)
    },
}

export default ApiService
