import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCategories } from 'services/CategoryService'

export const initialState = {
    list: [],
    data: [],
    loading: false,
}

export const getCategoryList = createAsyncThunk(
    'category/data/getCategories',
    async (data) => {
        const response = await getCategories(data)
        return response.data
    }
)

export const categoryFlowCountSlice = createSlice({
    name: 'categories/data',
    initialState,
    reducers: {
        setCategoriesCountData: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: {
        [getCategoryList.pending]: (state) => {
            state.loading = true
        },
        [getCategoryList.fulfilled]: (state, action) => {
            state.list = action.payload
            state.loading = false
        },
    },
})

export const { setCategoriesCountData } = categoryFlowCountSlice.actions

export default categoryFlowCountSlice.reducer
