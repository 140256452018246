import ApiService from './ApiService'

export async function getCategories() {
    return ApiService.fetchData({
        url: '/categories',
        method: 'get',
    })
}

export async function getCategoriesWithFlowCount() {
    return ApiService.fetchData({
        url: '/categories/count',
        method: 'get',
    })
}

export async function createCategory(data) {
    return ApiService.fetchData({
        url: '/categories',
        method: 'post',
        data,
    })
}

export async function updateCategory(categoryId, data) {
    return ApiService.fetchData({
        url: `/categories/${categoryId}`,
        method: 'put',
        data,
    })
}

export async function removeCategory(categoryId) {
    return ApiService.fetchData({
        url: `/categories/${categoryId}`,
        method: 'delete',
    })
}
