import React from 'react'
import { useConfig } from '../ConfigProvider'
import { ArrowDownAZ, ArrowDownUp, ArrowUpAZ } from 'lucide-react'

const Sorter = ({ sort }) => {
    const { themeColor, primaryColorLevel } = useConfig()

    const color = `text-${themeColor}-${primaryColorLevel}`

    const renderSort = () => {
        if (typeof sort !== 'boolean') {
            return <ArrowDownUp size={12} strokeWidth={1.5} />
        }
        return sort ? (
            <ArrowDownAZ size={12} strokeWidth={1.5} className={color} />
        ) : (
            <ArrowUpAZ size={12} strokeWidth={1.5} className={color} />
        )
    }

    return <div className="flex">{renderSort()}</div>
}

export default Sorter
