import React from 'react'
import classNames from 'classnames'
import { ArrowDown, ArrowRight, ArrowUp, ArrowLeft } from 'lucide-react'

const Arrow = ({ placement, color, colorDark }) => {
    const arrowDefaultClass = `absolute text-${color} dark:text-${colorDark}`

    const getArrow = () => {
        switch (placement) {
            case 'top':
                return (
                    <ArrowDown
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-bottom-2 w-full left-0'
                        )}
                    />
                )
            case 'top-start':
                return (
                    <ArrowDown
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-bottom-2 left-0 ml-3'
                        )}
                    />
                )
            case 'top-end':
                return (
                    <ArrowDown
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-bottom-2 right-0 mr-3'
                        )}
                    />
                )
            case 'right':
                return (
                    <ArrowLeft
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-left-2 top-1/2 transform -translate-y-1/2'
                        )}
                    />
                )
            case 'right-start':
                return (
                    <ArrowLeft
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-left-2 top-2'
                        )}
                    />
                )
            case 'right-end':
                return (
                    <ArrowLeft
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-left-2 bottom-2'
                        )}
                    />
                )
            case 'bottom':
                return (
                    <ArrowUp
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-top-2 w-full left-0'
                        )}
                    />
                )
            case 'bottom-start':
                return (
                    <ArrowUp
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-top-2 left-0 ml-3'
                        )}
                    />
                )
            case 'bottom-end':
                return (
                    <ArrowUp
                        size={16}
                        strokeWidth={1}
                        className={classNames(
                            arrowDefaultClass,
                            '-top-2 right-0 mr-3'
                        )}
                    />
                )
            case 'left':
                return (
                    <ArrowRight
                        strokeWidth={1}
                        size={20}
                        className={classNames(
                            arrowDefaultClass,
                            '-right-2 top-1/2 transform -translate-y-1/2'
                        )}
                    />
                )
            case 'left-start':
                return (
                    <ArrowRight
                        strokeWidth={1}
                        size={20}
                        className={classNames(
                            arrowDefaultClass,
                            '-right-2 top-2'
                        )}
                    />
                )
            case 'left-end':
                return (
                    <ArrowRight
                        strokeWidth={1}
                        size={20}
                        className={classNames(
                            arrowDefaultClass,
                            '-right-2 bottom-2'
                        )}
                    />
                )
            default:
                break
        }
    }

    return getArrow()
}

export default Arrow
