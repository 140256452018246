import React, { memo, useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import classNames from 'classnames'
import useDarkMode from 'utils/hooks/useDarkMode'
import SvgIcon from './SvgIcon'
import useThemeClass from 'utils/hooks/useThemeClass'
import { Search } from 'lucide-react'

const MenuList = (props) => {
    return (
        <components.MenuList {...props}>{props.children}</components.MenuList>
    )
}

const Input = (props) => {
    return (
        <components.Input {...props} className="text-inherit">
            {props.children}
        </components.Input>
    )
}

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        )
    )
}

const Control = ({ children, ...props }) => {
    return <components.Control {...props}>{children}</components.Control>
}

const SelectorDropdown = ({
    name,
    options,
    isMulti,
    isDisabled,
    isSearchable,
    placeholder,
    defaultValue,
    onChange,
    style,
    className,
    wrapperClass,
    closeMenuOnSelect,
    hideSelectedOptions,
    optionsChild,
    position,
    target,
    menuIsOpen = true,
    holderId,
    footer,
    maxMenuHeight,
    isOpen,
    setIsOpen,
    customSearchInput,
    customMenu,
    customNoOption,
    selectedValue = [],
}) => {
    const ref = useRef()
    const { ringTheme, borderTheme } = useThemeClass()
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = useCallback(
        (newValue) => {
            setInputValue(newValue)
        },
        [setInputValue]
    )

    // handle onChange event of the dropdown
    const handleChange = useCallback(
        (e) => {
            onChange?.(isMulti && Array.isArray(e) ? e.map((x) => x.value) : e)
        },
        [onChange, isMulti]
    )

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [isOpen])

    const getCurrentDimension = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension())
    const targetElement = document?.getElementById(holderId)
    const targetCoordinates = targetElement?.getBoundingClientRect()

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window?.addEventListener('resize', updateDimension)

        return () => {
            window?.removeEventListener('resize', updateDimension)
        }
    }, [screenSize])

    // here we decide what should be position of the dropdown menu
    position =
        screenSize?.width - targetCoordinates?.x > 250 &&
        targetCoordinates?.x > 250
            ? position || 'left-0'
            : screenSize?.width - targetCoordinates?.x > 250
            ? 'left-0'
            : 'right-0'

    const controlStyles = {
        base: 'border rounded-lg',
        focus: `focus:outline-none focus:ring-1 focus-within:ring-1 focus:${ringTheme} focus-within:${ringTheme} focus-within:${borderTheme} focus:${borderTheme}`,
        nonFocus: 'border-gray-300 dark:border-gray-600',
    }

    return (
        <Dropdown
            isOpen={isOpen}
            target={target}
            className={classNames(`${wrapperClass} ${position}`)}
            ref={ref}
        >
            <Select
                autoFocus
                name={name}
                defaultValue={defaultValue}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                placeholder={placeholder}
                components={{
                    ...(customNoOption
                        ? { NoOptionsMessage: customNoOption }
                        : null),
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Option: optionsChild,
                    MenuList,
                    Input: customSearchInput ? customSearchInput : Input,
                    Control,
                    ValueContainer,
                    ...(customMenu ? { Menu: customMenu } : null),
                }}
                controlShouldRenderValue={false}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                maxMenuHeight={maxMenuHeight}
                isClearable={false}
                menuIsOpen={menuIsOpen}
                isMulti={isMulti}
                options={options}
                inputValue={inputValue}
                value={options?.filter((o) =>
                    selectedValue?.includes(o?.value || o)
                )}
                onInputChange={handleInputChange}
                onChange={handleChange}
                className={className}
                menuPlacement="auto"
                styles={style}
                classNames={{
                    control: ({ isFocused }) =>
                        classNames(
                            isFocused
                                ? controlStyles.focus
                                : controlStyles.nonFocus,
                            controlStyles.base,
                            'mb-2'
                        ),
                }}
                tabSelectsValue={false}
                unstyled
            />
            {footer}
        </Dropdown>
    )
}

SelectorDropdown.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    wrapperClass: PropTypes.object,
    className: PropTypes.object,
    style: PropTypes.object,
    options: PropTypes.array.isRequired,
    position: PropTypes.string,
    holderId: PropTypes.string.isRequired,
    menuPlacement: PropTypes.string,
    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    optionsChild: PropTypes.func.isRequired,
    // a target element that will control open/close behavior of the dropdown
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // if any additional node to be displayed below menu list
    footer: PropTypes.node,
    maxMenuHeight: PropTypes.string,
    customSearchInput: PropTypes.func,
    customMenu: PropTypes.func,
    customNoOption: PropTypes.func,
}

SelectorDropdown.defaultProps = {
    placeholder: 'Search',
    isMulti: true,
    isDisabled: false,
    isSearchable: true,
    hideSelectedOptions: false,
    closeMenuOnSelect: false,
    maxMenuHeight: '12.5rem',
    wrapperClass: 'rounded py-5 px-4 absolute',
}

// styled components
const Menu = ({ isOpen, ...rest }) => {
    const inAnimation = { animation: 'inAnimation 150ms ease-in' }
    const outAnimation = {
        animation: 'outAnimation 150ms ease-out',
        animationFillMode: 'forwards',
    }

    const [isDark] = useDarkMode()

    return (
        <div
            style={{
                backgroundColor: isDark ? 'rgba(55,65,81,1)' : 'white',
                borderRadius: 8,
                border: isDark ? 'none' : '1px solid #E5E7EB',
                boxShadow:
                    '0px 89px 134px rgba(0, 0, 0, 0.02), 0px 37.1821px 55.982px rgba(0, 0, 0, 0.0143771), 0px 19.8793px 29.9307px rgba(0, 0, 0, 0.0119221), 0px 11.1442px 16.7789px rgba(0, 0, 0, 0.01), 0px 5.91859px 8.91114px rgba(0, 0, 0, 0.00807786), 0px 2.46286px 3.70812px rgba(0, 0, 0, 0.00562291',
                marginTop: 8,
                position: 'absolute',
                zIndex: 11,
                ...(isOpen ? inAnimation : outAnimation),
            }}
            {...rest}
        />
    )
}

const DropdownIndicator = () => <div />

// wrapper around select dropdown
const Dropdown = React.forwardRef(
    ({ children, isOpen, target, className, ...props }, ref) => {
        return (
            <div
                style={{
                    position: 'relative',
                }}
                {...props}
                ref={ref}
            >
                {target}
                {isOpen ? (
                    <Menu className={className} isOpen={isOpen}>
                        {children}
                    </Menu>
                ) : null}
            </div>
        )
    }
)

export default memo(SelectorDropdown)
